import React from "react"
import { Grid, Paper, Typography, Chip } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import  { LinkedInIcon, GitHubIcon, EmailIcon } from "../utils/socials"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  body: {
    fontWeight: 700,
    fontSize: `1.25em`,
  },
  heading: {
    marginBottom: `2.5rem`, 
    marginTop: `1.0rem`,
    textAlign: `center`
  },
  para: {
    padding: `20px`,
    fontFamily: `Work Sans`,
    fontWeight: `400`,
    '& a': {
      color: `purple`,
      fontWeight: `600`,
      textDecoration: `none`,
      '&:hover': {
        textDecoration: `underline`
      }
    }
  },
  a: {
    color: `purple`,
    fontWeight: `600`,
    textDecoration: `none`
  },
  chip: {
    margin: theme.spacing(0.5),
    width: `fit-content`,
    height: theme.spacing(6.0),
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  chipIcon: {
    marginTop: `24px`,
    padding: `2px`
  }
}));

const Contact = props => {
  const classes = useStyles();
  let icon = <img src="https://cosmic-s3.imgix.net/4cbfdef0-940d-11ea-bfc1-bf17d9eb5327-pdfDownload.svg" alt="pdf-download" width="24" className={classes.chipIcon}/>
  return (
    <div>
      <Typography variant="h5" className={classes.heading}>About Me</Typography>
      <Paper style={{ borderRadius: `15px` }}>
        <Grid container direction="column" alignItems="center" justify="center">
          <img 
            src='https://media-exp1.licdn.com/dms/image/C5603AQFHy29BVi3EfA/profile-displayphoto-shrink_200_200/0?e=1594857600&v=beta&t=4TrS3S5QvatR0kkxHUWpBZ_4XpUwGTJamOuw_PW6o2o'
            height="115" alt="zain-khalid" style={{ borderRadius: `48%`, padding: `15px` }}
          />
          <Typography variant="body1" className={classes.body}>Zain Khalid</Typography>
          <Typography variant="body1" style={{ fontSize: `0.85em` }}>Full Stack Web & Android Developer</Typography>
          <Grid container justify="center" spacing={2} style={{ marginTop: `10px`, marginBottom: `10px` }}>
            <Grid item><a href="https://linkedin.com/in/zain-khalid" target="_blank" rel="noopener noreferrer"><LinkedInIcon color="primary" /></a></Grid>
            <Grid item><a href="https://github.com/zain-ak" target="_blank" rel="noopener noreferrer"><GitHubIcon color="primary" /></a></Grid>
            <Grid item><a href="mailto:zain@zainkhalid.me?subject=Hello, Zain!" target="_blank" rel="noopener noreferrer"><EmailIcon color="primary" /></a></Grid>  
          </Grid>
          <Typography variant="body1" style={{ fontWeight: 700, fontSize: `1.35em` }}>Hi! <span role="img" aria-label="wave of the hand emoji">👋</span></Typography>
          <Typography variant="body1" className={classes.para}>
            I'm a software developer focused mainly on web development (React, Node.JS) and Android development.
            I'm currently completing an advanced diploma from Sheridan College (Canada) in <a
            href="https://academics.sheridancollege.ca/programs/computer-systems-technology-software-development-and-network-engineering"
            target="_blank" rel="noopener noreferrer">Software Development & Network Engineering</a> and I hold a Bachelor's degree in <a 
            href="https://www.uowdubai.ac.ae/computer-science-and-engineering-programs/bachelor-of-computer-science-bcompsc-degree"
            target="_blank" rel="noopener noreferrer">Computer Science</a> from
            the University of Wollongong in Dubai.
          </Typography>
          <Typography variant="body1" className={classes.para}>
            I'm always open to new opportunities to expand my skillset so if you're interested in working with me, or you'd like to talk then feel free to&nbsp;
            <a href="mailto:zain@zainkhalid.me?subject=Hello, Zain!"
            target="_blank" rel="noopener noreferrer">send an email!</a>
          </Typography>
          <Grid container justify="center" spacing={2} style={{ marginTop: `10px`, marginBottom: `10px` }}>
            <Grid item>
              <a href="https://cosmic-s3.imgix.net/d2039f00-95ea-11ea-9747-6342177db90e-Zain-Khalid-Full-Stack-Developer-Resume.pdf"
                 download="Zain Khalid Full Stack Developer Resume" target="_blank" rel="noopener noreferrer" className={classes.a}>
                <Chip icon={icon} label="Full Stack Developer Resume"  variant="outlined" size="medium" className={classes.chip} clickable />
              </a>
            </Grid>
            <Grid item>
              <a href="https://cosmic-s3.imgix.net/d20377f0-95ea-11ea-9747-6342177db90e-Zain-Khalid-Android-Developer-Resume.pdf"
                 download="Zain Khalid Android Developer Resume" target="_blank" rel="noopener noreferrer" className={classes.a}>
                <Chip icon={icon} label="Android Developer Resume" size="medium" variant="outlined" className={classes.chip} clickable />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default Contact
