import React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <SEO title="About | Contact" lang="en"
             description="Learn about me and find ways to reach me"
             meta={[`Zain`, `Khalid`, `Zain Khalid`, `Software`, `Software Developer`, `Developer`,
             `Full Stack`, `Full`, `Full Stack Developer`, `Android`, `Android Developer`, `Contact`, `About`]}
        />
        <Layout>
          <Contact />
        </Layout>
      </div>
    )
  }
}

export default AboutPage